<template>
  <div class="lookbook-product-group">
    <LookbookImageWrapper
      v-if="item.preview_photo"
      v-slot="image"
      :lookbook-slug="item.code"
      :products="item.products"
    >
      <CustomImage
        :alt="item.name"
        :image-id="item.preview_photo.id"
        aspect-ratio="48.14%"
        class="product-group-detail__image"
        @load="image.onLoaded"
      />
    </LookbookImageWrapper>
    <ul v-if="item.count_products" class="product-group__products">
      <li
        v-for="product in products"
        :key="product.id"
        class="product-group__products-item"
      >
        <component
          :is="getTagProduct(product)"
          class="product-group__products-link product-group__preview bg-image"
          v-bind="getPropsProduct(product)"
        >
          <CustomImage
            :alt="product.name"
            :height="imageSizes[1]"
            :image-id="product.image.id"
            :width="imageSizes[0]"
            class="product-group__preview-image"
          />
          <div
            v-if="product.price"
            class="product-group__preview-price font font_m font_bold"
          >
            {{ product.price | price }}
            <div
              v-if="visibleOldPrice(product)"
              class="product-group__preview-price-old font_grey font_sm"
            >
              {{ product.cost | price }}
            </div>
          </div>
        </component>
      </li>
    </ul>
  </div>
</template>

<script>
import LookbookImageWrapper from '@/components/catalog/lookbook/Image/Wrapper'
import CustomImage from '~/components/elements/CustomImage'
import './index.css'

export default {
  name: 'LookbookComponent',
  components: {
    CustomImage,
    LookbookImageWrapper,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    bannerImageSizes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    products() {
      return this.item?.products?.slice(0, 8) || []
    },
    mqIsMobile() {
      return (
        this.$mq === 'mobileXs' ||
        this.$mq === 'mobile' ||
        this.$mq === 'tablet'
      )
    },
    imageSizes() {
      return this.mqIsMobile ? [ 96, 96 ] : [ 122, 122 ]
    },
  },
  methods: {
    getTagProduct(product) {
      const categoryLink = this.getCategoryLink(product.categoryCodes)
      return categoryLink ? 'nuxt-link' : 'div'
    },
    getCategoryLink(categoryCodes) {
      return (
        categoryCodes &&
        categoryCodes.length > 0 &&
        categoryCodes[categoryCodes.length - 1]
      )
    },
    getPropsProduct(product) {
      const categoryLink = this.getCategoryLink(product.categoryCodes)
      if (categoryLink) {
        return {
          to: this.localePath({
            name: 'catalog.category.product',
            params: {
              category: categoryLink,
              product: product.code,
            },
          }),
        }
      }
      return false
    },
    visibleOldPrice(product) {
      const oldPrice = parseFloat(product.cost.value)
      const newPrice = parseFloat(product.price.value)
      return oldPrice && oldPrice !== newPrice
    },
  },
}
</script>
