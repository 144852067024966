<template>
  <ul class="product-group-info">
    <li v-if="count" class="product-group-info__item product-group-info__item--products">
      {{ phraseDeclension(count, productTextVariants) }}
    </li>
  </ul>
</template>

<script>
import { phraseDeclension } from '@/plugins/formatting';
import './index.css';

export default {
  name: 'ProductGroupInfo',
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      productTextVariants: [this.$t('shared.product.1'), this.$t('shared.product.5'), this.$t('shared.product.2')],
    };
  },
  methods: {
    phraseDeclension,
  },
};
</script>
