var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lookbook-points__item"},[_c('a',{key:_vm.product.id,staticClass:"lookbook-points__target",style:(`left: ${_vm.pointXCoordInPixels}px; top: ${_vm.pointYCoordInPixels}px`),attrs:{"id":`${_vm.lookbookSlug}__product-${_vm.product.id}`,"aria-label":_vm.$t('catalog.showProduct'),"href":_vm.localePath(`${_vm.lookbookSlug}__product-${_vm.product.id}`)},on:{"mouseover":(event) => event.target.focus(),"click":function($event){$event.preventDefault();}}}),_vm._v(" "),_c('div',{staticClass:"lookbook-points-tooltip",style:(`left: ${_vm.calculatedTooltipXCoordInPixels}px; top: ${_vm.calculatedTooltipYCoordInPixels}px`)},[_c('div',{staticClass:"lookbook-points-tooltip__image-wrapper"},[_c('CustomImage',{staticClass:"lookbook-points-tooltip__image",attrs:{"alt":_vm.product.name,"height":_vm.imageSizes[1],"image-id":_vm.product.image.id,"width":_vm.imageSizes[0]}})],1),_vm._v(" "),_c('div',{staticClass:"lookbook-points-tooltip__body"},[_c('span',{staticClass:"lookbook-points-tooltip__title"},[_vm._v(_vm._s(_vm.shortName))]),_vm._v(" "),_c('span',{staticClass:"lookbook-points-tooltip__subtitle"},[_vm._v("PYE")]),_vm._v(" "),_c('div',{staticClass:"lookbook-points-tooltip__footer"},[_c('span',{staticClass:"lookbook-points-tooltip__price"},[_vm._v("\n          "+_vm._s(_vm._f("price")(_vm.product.price))+"\n        ")]),_vm._v(" "),_c('nuxt-link',{staticClass:"lookbook-points-tooltip__link",attrs:{"to":_vm.localePath({
              name: 'catalog.category.product',
              params: {
                category: _vm.getCategoryLink(_vm.product.categoryCodes),
                product: _vm.product.code,
              },
            })}},[_c('svg-icon',{attrs:{"height":"14","name":"arrow-right","width":"16"}})],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }