<template>
  <section class="product-grid">
    <h2 class="visually-hidden">
      {{ $t('shared.breadcrumbs.catalog') }}
    </h2>
    <template v-if="items.length">
      <ul v-if="mqIsMobile" class="product-grid__list">
        <li v-for="product in items" :key="product.id" class="product-grid__item">
          <ProductCard :item="product" />
        </li>
      </ul>
      <ul v-else class="product-grid__list">
        <li v-for="product in items" :key="product.id" class="product-grid__item">
          <ProductCard :item="product" />
        </li>
      </ul>
    </template>
    <p v-else>
      {{ $t('catalog.product.notFound') }}
    </p>
  </section>
</template>

<script>
import ProductCard from '@/components/catalog/productCard';
import './index.css';

export default {
  name: 'Products',
  components: {
    ProductCard,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
  },
};
</script>
