<template>
  <div class="lookbook-points__item">
    <a
      :id="`${lookbookSlug}__product-${product.id}`"
      :key="product.id"
      :aria-label="$t('catalog.showProduct')"
      :href="localePath(`${lookbookSlug}__product-${product.id}`)"
      :style="`left: ${pointXCoordInPixels}px; top: ${pointYCoordInPixels}px`"
      class="lookbook-points__target"
      @mouseover="(event) => event.target.focus()"
      @click.prevent
    ></a>
    <div
      :style="`left: ${calculatedTooltipXCoordInPixels}px; top: ${calculatedTooltipYCoordInPixels}px`"
      class="lookbook-points-tooltip"
    >
      <div class="lookbook-points-tooltip__image-wrapper">
        <CustomImage
          :alt="product.name"
          :height="imageSizes[1]"
          :image-id="product.image.id"
          :width="imageSizes[0]"
          class="lookbook-points-tooltip__image"
        />
      </div>
      <div class="lookbook-points-tooltip__body">
        <span class="lookbook-points-tooltip__title">{{ shortName }}</span>
        <span class="lookbook-points-tooltip__subtitle">PYE</span>
        <div class="lookbook-points-tooltip__footer">
          <span class="lookbook-points-tooltip__price">
            {{ product.price | price }}
          </span>
          <nuxt-link
            :to="
              localePath({
                name: 'catalog.category.product',
                params: {
                  category: getCategoryLink(product.categoryCodes),
                  product: product.code,
                },
              })
            "
            class="lookbook-points-tooltip__link"
          >
            <svg-icon height="14" name="arrow-right" width="16" />
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomImage from '~/components/elements/CustomImage';
import './index.css';

export default {
  name: 'LookbookImagePoint',
  components: {
    CustomImage,
  },
  props: {
    lookbookSlug: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    widthWrapper: {
      type: Number,
      required: true,
    },
    heightWrapper: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      closingTimeout: null,
      maxPersents: 100,
      tooltipWidthInPixels: 224,
      tooltipHeightInPixels: 80,
      pointYOffsetInPixels: 24,
      edgeIndentInPixels: 12,
      nameMaxLength: 18,
      imageSizes: [64, 64],
    };
  },
  computed: {
    shortName() {
      if (this.product.name.length > this.nameMaxLength) {
        return `${this.product.name.slice(0, this.nameMaxLength)}...`;
      }
      return this.product.name;
    },
    pointXCoordInPercent() {
      const coords = this.product?.coordinates || '0,0';
      return Number.parseInt(coords.split(',')[0], 10);
    },
    pointYCoordInPercent() {
      const coords = this.product?.coordinates || '0,0';
      return Number.parseInt(coords.split(',')[1], 10);
    },
    pointXCoordInPixels() {
      const inPixelsValue = (this.widthWrapper / this.maxPersents) * this.pointXCoordInPercent;
      return Math.round(inPixelsValue);
    },
    pointYCoordInPixels() {
      const inPixelsValue = (this.heightWrapper / this.maxPersents) * this.pointYCoordInPercent;
      return Math.round(inPixelsValue);
    },
    calculatedTooltipXCoordInPixels() {
      const tooltipHeightCenterCoordInPixels = Math.round(this.tooltipWidthInPixels / 2);
      const tooltipHalfSizeWithOffsets = tooltipHeightCenterCoordInPixels + this.edgeIndentInPixels;
      const deltaXCoords = this.widthWrapper - this.pointXCoordInPixels;

      if (deltaXCoords < tooltipHalfSizeWithOffsets) {
        return this.widthWrapper - tooltipHalfSizeWithOffsets;
      } else if (this.pointXCoordInPixels < tooltipHalfSizeWithOffsets) {
        return tooltipHalfSizeWithOffsets;
      }
      return this.pointXCoordInPixels;
    },
    calculatedTooltipYCoordInPixels() {
      const tooltipHeightCenterCoordInPixels = Math.round(this.tooltipHeightInPixels / 2);
      const tooltipFullSizeWithOffsets =
        this.tooltipHeightInPixels + this.pointYOffsetInPixels + this.edgeIndentInPixels;
      const tooltipCenterYCoordInPixels = tooltipHeightCenterCoordInPixels + this.pointYOffsetInPixels;

      if (this.pointYCoordInPixels < tooltipFullSizeWithOffsets) {
        return this.pointYCoordInPixels + tooltipCenterYCoordInPixels;
      }
      return this.pointYCoordInPixels - tooltipCenterYCoordInPixels;
    },
  },
  methods: {
    getCategoryLink(categoryCodes) {
      return categoryCodes && categoryCodes.length > 0 && categoryCodes[categoryCodes.length - 1];
    },
  },
};
</script>
