<template>
  <article
    :class="{
      'product-group--set': isSet,
      'product-group--lookbook': isLookbook,
    }"
    class="product-group"
  >
    <!--    <header class="product-group__header">-->
    <!--      <span v-if="label" class="product-group__label">-->
    <!--        {{ label }}-->
    <!--      </span>-->
    <!--    </header>-->

    <nuxt-link :to="localePath(detailURL)" class="product-group__link">
      <h3 class="product-group__title font font_title-m font_bold">
        {{ group.name }}
      </h3>
    </nuxt-link>

    <nuxt-link v-if="isSet && group.preview_photo" :to="localePath(detailURL)" class="product-group__banner">
      <CustomImage
        :alt="group.preview_photo.caption || group.name"
        :image-id="group.preview_photo.id"
        aspect-ratio="48.14%"
        class="product-group__image"
      />
    </nuxt-link>

    <LookbookImageWrapper
      v-else-if="isLookbook && group.preview_photo"
      v-slot="image"
      :lookbook-slug="group.code"
      :products="group.products"
      class="product-group__image-wrapper"
    >
      <CustomImage
        :alt="group.preview_photo.caption || group.name"
        :image-id="group.preview_photo.id"
        aspect-ratio="48.14%"
        class="product-group__image"
        @load="image.onLoaded"
      />
    </LookbookImageWrapper>

    <ul v-if="group.count_products" class="product-group__products">
      <li v-for="(product, idx) in products" :key="`p-${product.id}-${idx}`" class="product-group__products-item">
        <component
          :is="getTagProduct(product)"
          class="product-group__products-link product-group__preview bg-image"
          v-bind="getPropsProduct(product)"
        >
          <CustomImage
            :alt="product.image.caption || product.name"
            :height="imageSizes[1]"
            :image-id="product.image.id"
            :width="imageSizes[0]"
            class="product-group__preview-image-wrap"
          />
          <div v-if="product.price && product.price.value" class="product-group__preview-price font font_m">
            <span class="font_bold">{{ product.price | price }}</span>
            <div v-if="visibleOldPrice(product)" class="product-group__preview-price-old font_grey font_sm">
              {{ product.cost | price }}
            </div>
          </div>
        </component>
      </li>
    </ul>

    <Button v-if="!isSet" :to="localePath(detailURL)" class="product-group__detail-link" full theme="secondary">
      {{ $t('catalog.allProducts') }}
    </Button>
  </article>
</template>

<script>
import Lookbook from '@/components/catalog/lookbook';
import LookbookImageWrapper from '@/components/catalog/lookbook/Image/Wrapper';
import ProductGroupInfo from '@/components/catalog/productGroupInfo';
import Button from '@/components/elements/Button';
import { phraseDeclension } from '@/plugins/formatting';
import { getImageAllFormats } from '@/plugins/helpers/file';
import CustomImage from '~/components/elements/CustomImage';
import './index.css';

export default {
  name: 'ProductGroup',
  components: {
    CustomImage,
    Lookbook,
    LookbookImageWrapper,
    ProductGroupInfo,
    Button,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      productTextVariants: [this.$t('shared.product.1'), this.$t('shared.product.5'), this.$t('shared.product.2')],
    };
  },
  computed: {
    otherProductsCount() {
      return this.group.count_products - 2;
    },
    label() {
      return this.group.type?.name;
    },
    products() {
      return this.group.products.slice(0, 4);
    },
    isLookbook() {
      return this.group.type?.code === 'lookbooks';
    },
    isSet() {
      return this.group.type?.code === 'sets';
    },
    detailURL() {
      return `/${this.group.type.code}/${this.group.code}`;
    },
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'laptop';
    },
    imageSizes() {
      return this.mqIsMobile ? [96, 96] : [122, 122];
    },
  },
  methods: {
    phraseDeclension,
    getImage(id, type = 'item') {
      if (type === 'preview') {
        return getImageAllFormats(id);
      }

      return getImageAllFormats(id, this.imageSizes[0], this.imageSizes[1]);
    },
    getCategoryLink(categoryCodes) {
      return categoryCodes && categoryCodes.length > 0 && categoryCodes[categoryCodes.length - 1];
    },
    getTagProduct(product) {
      const categoryLink = this.getCategoryLink(product.categoryCodes);
      return categoryLink ? 'nuxt-link' : 'div';
    },
    getPropsProduct(product) {
      const categoryLink = this.getCategoryLink(product.categoryCodes);
      if (categoryLink) {
        return {
          to: this.localePath({
            name: 'catalog.category.product',
            params: {
              category: categoryLink,
              product: product.code,
            },
          }),
        };
      }
      return false;
    },
    visibleOldPrice(product) {
      const oldPrice = parseFloat(product.cost);
      const newPrice = parseFloat(product.price?.value);
      return oldPrice && oldPrice !== newPrice;
    },
  },
};
</script>
